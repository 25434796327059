
import { reactive, ref } from "vue"
import axios from "axios"
import { useRoute } from "vue-router"
import OrganizationService from "@/services/OrganizationService"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"
import Button from "primevue/button"
import SecondaryButton from "../buttons/SecondaryButton.vue"

export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props: any) {
    const route = useRoute()
    const data = reactive([])
    const loading = ref(false)
    const organizationService = OrganizationService.getInstance()
    const isStaging = process?.env?.VUE_APP_DATABASE_NAME == "Staging"
    const toast = useToast()
    const confirm = useConfirm()
    function loadStats() {
      loading.value = true
      return axios
        .get("studies/overview/support?organizationId=" + route.params.organization_id, {
          baseURL: process.env.VUE_APP_VSOL_API_URL + "v0",
        })
        .then((res) => {
          loading.value = false
          data.length = 0
          ;[].push.apply(data, res.data)
        })
    }

    function cloneEquineFromDonor(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Clone organization",
        message: "Are you sure you want to clone equine images from Donor to this organization?",
        accept: () => {
          if (!isStaging) {
            organizationService.cloneEquineFromOrg(props.organization.id).then(() => {
              toast.add({
                severity: "success",
                summary: "Success",
                detail: "Cloned from Donor",
                life: 3000,
              })
            })
          } else {
            toast.add({
              severity: "info",
              summary: "Warning",
              detail: "Donor org is only available on production",
              life: 3000,
            })
          }
        },
      })
    }

    function cloneSmallAnimalsFromDonor(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Clone organization",
        message:
          "Are you sure you want to clone small animals images from Donor_SA to this organization?",
        accept: () => {
          if (!isStaging) {
            organizationService.cloneSmallAnimalFromOrg(props.organization.id).then(() => {
              toast.add({
                severity: "success",
                summary: "Success",
                detail: "Cloned from Donor_SA",
                life: 3000,
              })
            })
          } else {
            toast.add({
              severity: "info",
              summary: "Warning",
              detail: "Donor_SA org is only available on production",
              life: 3000,
            })
          }
        },
      })
    }

    return {
      data,
      loading,
      loadStats,
      cloneEquineFromDonor,
      cloneSmallAnimalsFromDonor,
    }
  },
  components: { Button, SecondaryButton },
}
